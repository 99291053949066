import { useEffect, useRef, useState } from 'react';
import { Canvas, useThree, useFrame } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Vector3, Raycaster, SphereGeometry, Mesh, MeshBasicMaterial } from 'three';
import { OrbitControls } from '@react-three/drei';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { Maximize2, Minimize2, RotateCcw } from 'lucide-react';
import { motion } from 'framer-motion';

const LoadingScreen = () => (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-blue-500">
    <motion.div
      className="text-white text-4xl font-bold"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      Loading Rescue Johnny...
    </motion.div>
    <motion.div
      className="absolute bottom-10 w-64 h-2 bg-blue-300 rounded-full overflow-hidden"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      transition={{ duration: 2, ease: "easeInOut" }}
    >
      <motion.div
        className="h-full bg-white"
        initial={{ width: "0%" }}
        animate={{ width: "100%" }}
        transition={{ duration: 2, ease: "easeInOut" }}
      />
    </motion.div>
  </div>
);

const CountingGameContent = ({ audioRef, endAudioRef, numberAudioRefs, onReload, setIsLoading }) => {
  const sceneRef = useRef();
  const cameraRef = useRef();
  const baseSceneRef = useRef();
  const [shakingSharks, setShakingSharks] = useState(new Map());
  const [nets, setNets] = useState([]);
  const [sharkCount, setSharkCount] = useState(0);
  const { scene, camera } = useThree();
  const addedNetsPositionsRef = useRef([]);

  useEffect(() => {
    const loadScene = async () => {
      const loader = new GLTFLoader();
      const gltf = await loader.loadAsync('/assets/base_scene.glb');
      baseSceneRef.current = gltf.scene;
      scene.add(baseSceneRef.current);

      camera.position.set(0, 2, -10);
      camera.lookAt(0, -0.5, 0);
      camera.zoom = 8;
      camera.updateProjectionMatrix();

      baseSceneRef.current.traverse((object) => {
        if (object.isMesh) {
          object.userData.name = object.name;
          object.userData.clickable = true;
        }
      });

      const defaultMaterial25 = baseSceneRef.current.getObjectByName('defaultMaterial_25');
      let sharkLocations = [[-0.5, 0, -0.5], [-0.2, 0, -0.3],[-0.2, 0, 0.25], [-0.1, 0, 0.9], [-0.2, 0, 0.5], [-0.6, 0, 0], [-0.7, 0, 0.5],[-0.5, 0, 1], [-0.1, 0, -0.8]]
      if (defaultMaterial25) {
        sharkLocations.forEach(location => {
          const clone = defaultMaterial25.clone();
          clone.position.set(location[0]+Math.random()*0.1, location[1], location[2]+Math.random()*0.1);
          scene.add(clone);
        });
      }
      
      setIsLoading(false);
    };

    loadScene();

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [scene, camera, setIsLoading]);

  const raycaster = new Raycaster();
  const handleClick = (event) => {
    const mouse = new Vector3(
      (event.clientX / window.innerWidth) * 2 - 1,
      -(event.clientY / window.innerHeight) * 2 + 1,
      0.5
    );

    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObjects(scene.children, true);
    const clickableIntersects = intersects.filter(
      (intersect) => intersect.object.userData.clickable
    );

    if (clickableIntersects.length > 0) {
      const clickedObject = clickableIntersects[0].object;
      console.log(`Clicked on: ${clickedObject.userData.name}`);
      if (clickedObject.name === 'defaultMaterial_25') {
        audioRef.current.play();

        const originalPosition = clickedObject.position.clone();
        setShakingSharks(prevShakingSharks => {
          const newShakingSharks = new Map(prevShakingSharks);
          newShakingSharks.set(clickedObject, originalPosition);
          return newShakingSharks;
        });
        setTimeout(() => {
          setShakingSharks(prevShakingSharks => {
            const updatedShakingSharks = new Map(prevShakingSharks);
            if (updatedShakingSharks.has(clickedObject)) {
              clickedObject.position.copy(originalPosition);
              updatedShakingSharks.delete(clickedObject);
            }
            return updatedShakingSharks;
          });
        }, 300);

        const clickedPositionString = `${clickedObject.position.x},${clickedObject.position.y},${clickedObject.position.z}`;
        if (!addedNetsPositionsRef.current.includes(clickedPositionString)) {
          console.log(addedNetsPositionsRef.current);
          createNet(clickedObject.position.clone().add(new Vector3(-0.6, -0.25, -0.2)));
          addedNetsPositionsRef.current = [...addedNetsPositionsRef.current, clickedPositionString];
          console.log(addedNetsPositionsRef.current);
          numberAudioRefs.current[addedNetsPositionsRef.current.length-1].play();
          if (addedNetsPositionsRef.current.length === 10) {
            setTimeout(() => {
              endAudioRef.current.play();
            }, 1500);
          }
        }
      }
    }
  };

  const createNet = (position) => {
    const netMaterial = new MeshBasicMaterial({ color: 'gray', wireframe: true });
    const netGeometry = new SphereGeometry(0.15, 32, 32);

    const net = new Mesh(netGeometry, netMaterial);
    net.position.copy(position);

    setNets(prevNets => [...prevNets, net]);
    setSharkCount(prevCount => prevCount + 1);

    const fontLoader = new FontLoader();
    fontLoader.load('/fonts/helvetiker_regular.typeface.json', (font) => {
      const textGeometry = new TextGeometry(`${sharkCount + 1}`, {
        font: font,
        size: 0.1,
        height: 0.02,
      });
      const textMaterial = new MeshBasicMaterial({ color: 'magenta' });
      const textMesh = new Mesh(textGeometry, textMaterial);
      textMesh.position.copy(position).add(new Vector3(0, 0.2, 0));
      textMesh.userData.isText = true;
      scene.add(textMesh);
    });
  };

  useEffect(() => {
    const fontLoader = new FontLoader();
    fontLoader.load('/fonts/helvetiker_regular.typeface.json', (font) => {
      nets.forEach((net, index) => {
        const textMesh = scene.children.find(child => child.isMesh && child.geometry instanceof TextGeometry && child.position.equals(net.position.clone().add(new Vector3(0, 0.2, 0))));
        if (textMesh) {
          textMesh.geometry.dispose();
          const textGeometry = new TextGeometry(`${index + 1}`, {
            font: font,
            size: 0.1,
            height: 0.02,
          });
          textMesh.geometry = textGeometry;
        }
      });
    });
  }, [sharkCount, nets, scene]);

  useFrame(() => {
    setShakingSharks(prevShakingSharks => {
      const newShakingSharks = new Map(prevShakingSharks);
      newShakingSharks.forEach((originalPosition, object) => {
        object.position.y += (Math.random() - 0.5) * 0.1;
      });
      return newShakingSharks;
    });

    scene.children.forEach(child => {
      if (child.userData.isText) {
        child.lookAt(camera.position);
      }
    });
  });

  return (
    <>
      <OrbitControls enablePan={false} 
        minDistance={3} 
        maxDistance={10}
        maxPolarAngle={Math.PI / 2}
      />
      {nets.map((net, index) => (
        <primitive key={index} object={net} />
      ))}
    </>
  );
};

const CountingGame = () => {
  const audioRef = useRef(new Audio('/audios/water-splash.mp3'));
  const endAudioRef = useRef(new Audio('/audios/end.mp3'));
  const numberAudioRefs = useRef(
    Array.from({ length: 10 }, (_, i) => new Audio(`/audios/${i + 1}.mp3`))
  );
  const seaSoundRef = useRef(new Audio('/audios/sea_sound.wav'));
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [key, setKey] = useState(0);
  const cursorRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const introAudioRef = useRef(new Audio('/audios/intro.mp3'));

  useEffect(() => {
    const cursor = document.createElement('img');
    cursor.src = '/images/cursor.webp';
    cursor.style.position = 'fixed';
    cursor.style.pointerEvents = 'none';
    cursor.style.zIndex = '9999';
    cursor.style.width = '50px';
    cursor.style.height = '50px';
    cursor.style.transform = 'rotate(-60deg)';
    document.body.appendChild(cursor);
    cursorRef.current = cursor;

    const updateCursorPosition = (e) => {
      cursor.style.left = `${e.clientX-20}px`;
      cursor.style.top = `${e.clientY-20}px`;
    };
    document.addEventListener('mousemove', updateCursorPosition);

    const animateCursor = () => {
      cursor.style.transition = 'transform 0.1s ease-out';
      cursor.style.transform = 'rotate(-180deg)';
      setTimeout(() => {
        cursor.style.transition = 'transform 0.3s ease-out';
        cursor.style.transform = 'rotate(-60deg)';
      }, 100);
    };
    document.addEventListener('click', animateCursor);

    // Hide the default cursor
    document.body.style.cursor = 'none';

    seaSoundRef.current.loop = true;
    
    const playAudio = async () => {
      try {
        await seaSoundRef.current.play();
      } catch (error) {
        console.error('Error playing audio:', error);
      }
    };

    playAudio();

    // Attempt to launch fullscreen by default, but handle cases where it's not supported
    const tryEnterFullscreen = async () => {
      // Check if the device is iOS
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      
      if (isIOS) {
        // For iOS devices, we'll use a different approach
        document.body.style.position = 'fixed';
        document.body.style.top = '0';
        document.body.style.left = '0';
        document.body.style.width = '100%';
        document.body.style.height = '100%';
        document.body.style.overflow = 'hidden';
        setIsFullscreen(true);
      } else {
        // For non-iOS devices, try to use the Fullscreen API
        if (!document.fullscreenElement && !document.webkitFullscreenElement) {
          try {
            if (document.documentElement.requestFullscreen) {
              await document.documentElement.requestFullscreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
              await document.documentElement.webkitRequestFullscreen();
            }
            setIsFullscreen(true);
          } catch (err) {
            console.log(`Fullscreen request failed: ${err.message}`);
            // Fallback for devices that don't support fullscreen
            document.body.style.position = 'fixed';
            document.body.style.top = '0';
            document.body.style.left = '0';
            document.body.style.width = '100%';
            document.body.style.height = '100%';
            document.body.style.overflow = 'hidden';
            setIsFullscreen(true);
          }
        }
      }
    };

    tryEnterFullscreen();

    // Change to landscape mode if on mobile
    if (window.screen && window.screen.orientation && window.screen.orientation.lock) {
      window.screen.orientation.lock('landscape').catch(err => {
        console.log(`Unable to lock screen orientation: ${err.message} (${err.name})`);
      });
    }

    return () => {
      seaSoundRef.current.pause();
      seaSoundRef.current.currentTime = 0;
      document.removeEventListener('mousemove', updateCursorPosition);
      document.removeEventListener('click', animateCursor);
      document.body.removeChild(cursor);
      // Restore the default cursor
      document.body.style.cursor = 'auto';
    };
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const playIntroAudio = async () => {
        try {
          await new Promise(resolve => setTimeout(resolve, 1000)); // 1 second delay
          await introAudioRef.current.play();
        } catch (error) {
          console.error('Error playing intro audio:', error);
        }
      };

      playIntroAudio();
    }

    return () => {
      introAudioRef.current.pause();
      introAudioRef.current.currentTime = 0;
    };
  }, [isLoading]);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement && !document.webkitFullscreenElement) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.webkitRequestFullscreen) { // iOS Safari
        document.documentElement.webkitRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { // iOS Safari
        document.webkitExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  const reloadScene = () => {
    setKey(prevKey => prevKey + 1);
  };

  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
      {isLoading && <LoadingScreen />}
      <Canvas key={key} style={{ width: '100%', height: '100%' }}>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <CountingGameContent 
          audioRef={audioRef} 
          endAudioRef={endAudioRef} 
          numberAudioRefs={numberAudioRefs}
          seaSoundRef={seaSoundRef}
          onReload={reloadScene}
          setIsLoading={setIsLoading}
        />
      </Canvas>
      {!isLoading && (
        <div style={{ position: 'absolute', bottom: '20px', left: '20px', zIndex: 1000 }}>
          <button onClick={toggleFullscreen} style={{ marginRight: '10px', background: 'none', border: 'none', cursor: 'pointer' }}>
            {isFullscreen ? <Minimize2 size={24} color="white" /> : <Maximize2 size={24} color="white" />}
          </button>
          <button onClick={reloadScene} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
            <RotateCcw size={24} color="white" />
          </button>
        </div>
      )}
    </div>
  );
};

export default CountingGame;
