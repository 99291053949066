import React from 'react';
import CountingGameLoader from './components/CountingGameLoader';
import './App.css';

function App() {
  return (
    <div className="App">
      <CountingGameLoader />
    </div>
  );
}

export default App;